<template>
  <div class="loader-wrapper">
    <div class="loader is-loading">
    </div>
    <div
      v-if="geolocating"
      class="geolocator"
    >
      <i class="far fa-compass"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    geolocating: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
  .loader-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .loader-wrapper .loader,
  .loader-wrapper .geolocator {
    display: flex;
  }
  .loader-wrapper .loader {
    height: 80px;
    width: 80px;
  }
  .loader-wrapper .geolocator {
    margin-top: -80px;
    font-size: 60px;
    padding: 10px;
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    50% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
</style>
