<template>
  <div class="container">
    <!-- Main container -->
    <nav class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <div 
            class="field has-addons"
          >
            <BaseDestinationSearch
              v-if="typeFilter != 'user' && typeFilter != 'claimed'"
              v-model="location"
              :hasGeolocation="hasGeolocation"
            />
            <p class="control">
              <span class="select is-size-7">
                <select 
                  v-model="typeFilter"
                  @change="updateDeals"
                >
                  <option
                    v-for="o in computed_filter_options"
                    :key="o.value"
                    :value="o.value"
                  >
                    {{ o.text }}
                  </option>
                </select>
              </span>
            </p>
            <p 
              v-if="locationFilter == 'my-location' && typeFilter != 'user' && typeFilter != 'claimed'"
              class="control"
            >
              <span class="select is-size-7">
                 <select 
                  v-model="distanceFilter"
                  @change="updateDeals"
                 >
                  <option
                    v-for="o in distance_options"
                    :key="o.value"
                    v-bind:value="o.value"
                  >
                  within {{ o.value }} mi
                  </option>
                </select>
              </span>
            </p>
          </div>
        </div>
      </div>
    </nav>

    <VueDataLoading
      :loading="loading"
      :listens="['pull-down']"
      @pull-down="updateDeals"
    >
      <div slot="pull-down-before">
        Pull down to refresh
      </div>
      <div slot="pull-down-ready">
        Release to refresh
      </div>
      <div slot="pull-down-loading">
        Refreshing...
      </div>

      <Notification
        v-if="error"
        type="danger"
      >
        {{ error }}
      </Notification>

      <Loader
        v-if="loading"
        :geolocating="geolocating"
      />
      <div
        v-else
        class="container"
      >
        <div
          v-if="typeFilter == 'user'"
          class="level"
        >
          <div class="level-item">
            <button
              class="button is-small"
              @click="newDeal()"
            >
              Add another deal
            </button>
          </div>
        </div>
        <div
          v-else-if="typeFilter == 'claimed'"
          class="level"
        >
          <div class="level-item">
            <button
              class="button is-small"
              @click="transactions"
            >
              All Transaction History
            </button>
          </div>
        </div>
        <div 
          class="columns is-multiline"
          v-if="deals.length"
        >
          <div
            v-for="deal in deals"
            :key="deal.slug + '-' + deal.location_id"
            class="column is-half-tablet is-half-desktop is-one-third-widescreen"
          >
            <DealCard 
              :deal="deal"
              :showDistance="showDistance" 
            />
          </div>
        </div>
        <div
          v-else
          class="has-text-centered content box" 
        >
          <h2>
            <span class="icon is-large">
              <i class="fa fa-2x fa-exclamation-triangle"></i>
            </span>
          </h2>
          <div
            v-if="!hasGeolocation"
          >
            <Notification
              type="warning"
            >
              Please allow your device to detect your current location.
            </Notification>
            <p class="block">You can  search for a specific city &amp; state above, but will not be able to claim deals without access to your current location.</p>
          </div>
          <div
            v-else-if="showDistance"
            class="block"
          >
            <p class="block">There are no deals within a <b>{{ distanceFilter }} mi</b> radius.</p>
            <p class="block">Please expand your search radius or search for a specific city &amp; state above.</p>
          </div>
          <p
            v-else
            class="block"
          >
            There are no deals available at this time.
          </p>

          <p
            v-if="hasBusiness || isMasqueradingAsBusiness"
            class="block"
          >
            View your own <a @click="go('/deals/user/my-location/100')">deals</a>.
          </p>

          <div
            v-if="!loggedIn"
          >
            <hr />
            <h2>
              <span class="icon is-large">
                <i class="fa fa-2x fa-envelope"></i>
              </span>
            </h2>
            <button
              class="button is-link"
              @click="subscribe"
            >
              Subscribe for Updates
            </button>
          </div>

          <hr />
          <p class="block">Thanks for checking out <Brand />!</p>
          <p class="block">We’re just getting started and building up our list of deals, so please check back again soon if you don’t see any deals near you.</p>
        </div>
      </div>
    </VueDataLoading>
  </div>
</template>

<script>
import {AuthError, NetworkError} from "@/errors";
import Brand from "@/components/Brand.vue";
import Loader from "@/components/Loader.vue";
import VueDataLoading from "vue-data-loading";
import Notification from "@/components/Notification.vue";
import BaseDestinationSearch from "@/components/form/BaseDestinationSearch.vue";
import DealCard from "@/components/DealCard.vue";

export default {
  props: {
    filter: {
      type: String
    },
    distance: {
      type: [String, Number]
    },
    location: {
      type: String
    }
  },
  components: {
    Brand,
    Loader,
    VueDataLoading,
    Notification,
    BaseDestinationSearch,
    DealCard,
  },
  computed: {
    loggedIn: function() {
      return this.$store.getters.isLoggedIn
    },
    currentUser: function() {
      return this.$store.getters.currentUser;     
    },
    isAdmin: function() {
      return this.$store.getters.isAdmin;
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness;     
    },
    hasBusiness: function() {
      return this.$store.getters.hasBusinessAccess;
    },
    isOwnBusiness: function() {
      return this.currentUser.organization_access.some(b => b.slug === this.currentBusiness.slug);
    },
    isMasqueradingAsBusiness: function() {
      return (this.currentBusiness && this.currentBusiness.slug && this.isAdmin && !this.isOwnBusiness);
    },
    hasGeolocation: function() {
      return (this.geolocationError || !this.$store.getters.currentLocation) ? false : true;
    },
    showDistance: function() {
      return (this.locationFilter == 'my-location' && this.typeFilter != 'user' && this.typeFilter != 'claimed') ? true : false
    },
    typeFilter: {
      get: function () {
        if (this.filter) {
          return this.filter
        } else if ((this.$store.getters.dealsFilter == "user") && (!this.currentBusiness || !this.currentBusiness.slug)) {
          return "all"
        }
        return this.$store.getters.dealsFilter
      },
      set: function (newValue) {
        this.$store.dispatch("filterDeals", newValue)
        if (this.filter && this.filter != newValue) {
          this.go('/')
        }
      }
    },
    locationFilter: {
      get: function () {
        return (this.location) ? this.location : this.$store.getters.dealsLocationFilter
      },
      set: function (newValue) {
        this.$store.dispatch("filterDealsLocation", newValue)
        if (this.location && this.location != newValue) {
          this.go('/')
        }
      }
    },
    distanceFilter: {
      get: function () {
        const distance = this.$store.getters.dealsDistanceFilter
        if (!this.distance && !distance && this.currentUser && this.currentUser.slug != undefined) {
          return this.currentUser.notification_radius
        } else if (this.distance) {
          return this.distance
        } else if (distance) {
          return distance
        }
        return 100;
      },
      set: function (newValue) {
        this.$store.dispatch("filterDealsDistance", newValue)
        if (this.distance && this.distance != newValue) {
          this.go('/')
        }
      }
    },
    computed_filter_options: function() {
      if (this.currentUser && this.currentUser.slug != undefined && this.currentBusiness && this.currentBusiness.slug != undefined) {
        return this.filter_options_business
      } else if (this.currentUser && this.currentUser.slug != undefined) {
        return this.filter_options_user
      } else {
        return this.filter_options
      }
    }
  },
  data() {
    return {
      loading: false,
      geolocating: false,
      error: null,
      geolocationError: null,
      gettingLocation: false,
      filter_options_user: [   
        {
          text: "All deals",
          value: "all"
        },
        {
          text: "Claimed deals",
          value: "claimed"
        },
        {
          text: "To do",
          value: "todo"
        },
        {
          text: "Eat & Drink",
          value: "eat"
        },
        {
          text: "Stay",
          value: "stay"
        },
        {
          text: "Shop",
          value: "shop"
        },
        {
          text: "Services",
          value: "services"
        }
      ],
      filter_options_business: [   
        {
          text: "All deals",
          value: "all"
        },
        {
          text: "Claimed deals",
          value: "claimed"
        },
        {
          text: "My deals",
          value: "user"
        },
        {
          text: "To do",
          value: "todo"
        },
        {
          text: "Eat & Drink",
          value: "eat"
        },
        {
          text: "Stay",
          value: "stay"
        },
        {
          text: "Shop",
          value: "shop"
        },
        {
          text: "Services",
          value: "services"
        }
      ],
      filter_options: [   
        {
          text: "All deals",
          value: "all"
        },
        {
          text: "To do",
          value: "todo"
        },
        {
          text: "Eat & Drink",
          value: "eat"
        },
        {
          text: "Stay",
          value: "stay"
        },
        {
          text: "Shop",
          value: "shop"
        },
        {
          text: "Services",
          value: "services"
        }
      ],
      distance_options: [   
        {
          value: 1
        },
        {
          value: 5
        },
        {
          value: 10
        },
        {
          value: 25
        },
        {
          value: 50
        },
        {
          value: 100
        }
      ],
      deals: []
    }
  },
  watch: {
    filter: function () {
      this.updateDeals()
    },
    location: function () {
      this.updateDeals()
    },
    distance: function () {
      this.updateDeals()
    }
  },
  mounted() {
    if (window.NativeScript) {
      window.NativeScript.setLoginState(this.$store.getters.isLoggedIn);
    }
    this.updateDeals()
  },
  methods: {
    go(path) {
      this.$router.push(path)
    },
    getDealsUrl(currentLocation) {
      const path = `${this.typeFilter}/${this.locationFilter}/${this.distanceFilter}`

      if (this.locationFilter == 'my-location' && currentLocation && currentLocation.lat != undefined && currentLocation.lng != undefined) {  
        return path + "/?lat=" + currentLocation.lat + "&lng=" + currentLocation.lng
      } else {
        return path
      }
    },
    getUserSlug() {
      if (this.currentUser && this.currentUser.slug != undefined) {
        return this.currentUser.slug
      } else {
        return null
      }
    },
    subscribe() {
      this.go('/subscribe')
    },
    transactions() {
      this.go('/transactions')
    },
    newDeal() {
      const path = `/deal/${this.currentBusiness.slug}/new/l/none/edit`
      this.$router.push(path)
    },
    updateDeals() {
      if (this.loading) {
        return
      }

      this.error = null
      this.geolocationError = null
      this.loading = true
      this.$store.dispatch("clearDeal")

      this.geolocating = true
      this.$store.dispatch("getLocation")
      .catch(err => {
        console.log(err)
        if (err instanceof AuthError || err instanceof NetworkError) {
          throw err
        } else {
          this.geolocationError = err
          this.loading = false
        }
      })
      .finally(() => {
        const currentLocation = this.$store.getters.currentLocation;
        this.geolocating = false

        if (this.geolocationError != null && this.locationFilter == 'my-location') {
          this.deals = []
          this.loading = false
        } else {
          const dealsUrl = this.getDealsUrl(currentLocation)

          this.$store.dispatch("getDeals", dealsUrl)
          .then(resp => {
            this.deals = resp
          })
          .catch(error => {
            if (error instanceof AuthError || error instanceof NetworkError) {
              throw error
            } else {
              this.error = error
            }
          })
          .finally(() => {
            this.loading = false
          })
        }
      })
    }
  }
}
</script>
