<template>
  <b-autocomplete
    ref="autocomplete"
    v-model="query"
    :data="filteredDataObj"
    field="text"
    :icon="icon"
    :placeholder="placeholder"
    :loading="loading"
    @select="onSelect"
    size="is-small"
    open-on-focus
    clearable
    expanded
    autocomplete="search"
  >
    <template #header>
      <a
        v-if="query && hasGeolocation"
        class="icon-text"
        @click="myLocation"
      >
        <span class="icon">
          <i class="fas fa-location-arrow"></i>
        </span>
        <span class="is-size-7">Near Me</span>
      </a>
      <span
        v-else
        class="icon-text has-text-grey-light"
      >
        <span class="icon">
          <i class="fas fa-search-location"></i>
        </span>
        <span class="is-size-7">Search city/state</span>
      </span>
    </template>
    <template slot-scope="props">
      <span class="icon-text">
        <span class="icon">
          <i class="fas fa-map-marker-alt"></i>
        </span>
        <span class="is-size-7">{{ props.option.text }}</span>
      </span>
    </template>
    <template #empty>No results for {{ query }}</template>
  </b-autocomplete>
</template>

<script>
import States from "us-state-codes"
import {AuthError, NetworkError} from "@/errors"

export default {
  props: {
    value: {
      type: String
    },
    hasGeolocation: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      destinations: [],
      loading: false,
      query: "",
      selected: null
    }
  },
  computed: {
    filteredDataObj() {
      return this.destinations.filter(option => {
        const match = option.text.match(/\s([A-Z]{2})$/)
        const state = (match.length > 1 && match[1]) ? States.getStateNameByStateCode(match[1]) : ""
        return (
          option.text
            .toString()
            .toLowerCase()
            .indexOf(this.query.toLowerCase()) >= 0
          || state
            .toString()
            .toLowerCase()
            .indexOf(this.query.toLowerCase()) >= 0
        )
      })
    },
    icon: function() {
      return (this.hasGeolocation && this.query === "") ? "location-arrow" : "search-location"
    },
    placeholder: function() {
      if (this.hasGeolocation) {
        return (!this.selected) ? "Near Me" : ""
      } else {
        return (!this.selected) ? "Search city/state" : ""
      }
    }
  },
  watch: {
    value: function() {
      this.query = (this.value === "my-location") ? "" : this.value
    }
  },
  methods: {
    updateDestinations() {
      this.loading = true
      return this.$store.dispatch("getDestinations")
      .then(() => {
        this.destinations = this.$store.getters.destinations
      })
      .catch(error => {
        if (error instanceof AuthError || error instanceof NetworkError) {
          throw error
        } else {
          this.error = error
        }
      })
      .finally(() => {
        this.loading = false
      })
    },
    onSelect(option) {
      this.selected = option
      this.$emit('input', (!this.selected) ? 'my-location' : this.selected.value)
    },
    myLocation() {
      this.$refs.autocomplete.setSelected(null)
    }
  },
  mounted() {
    this.updateDestinations()
  }
}
</script>
